<template>
  <v-dialog :value="load"
            @keydown.esc="closePasswordChangeForm"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="($vuetify.breakpoint.sm ? '80%' :
              ($vuetify.breakpoint.md ? '60%' : '40%'))"
            transition="scale-transition" persistent>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <span class="headline">{{ $t('password_change.form__header') }}</span>

        <v-spacer></v-spacer>

        <v-btn @click="closePasswordChangeForm"
               icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" @submit.prevent="updatePassword">
          <v-layout row wrap justify-center>
            <v-flex xs12 sm11 md10 mb-4>
              <v-alert v-model="alert.display"
                       :type="alert.type"
                       dismissible>
                {{ alert.text }}
              </v-alert>
            </v-flex>

            <v-flex xs10>
              <v-text-field v-model="old_password"
                            :label="$t('common.field__password-header')"
                            prepend-inner-icon="keyboard"
                            :append-icon="visible_old_password ? 'visibility_off' : 'visibility'"
                            @click:append="visible_old_password=!visible_old_password"
                            :type="visible_old_password ? 'text' : 'password'"
                            :rules="passwordRules"
                            clearable
              ></v-text-field>
            </v-flex>

            <v-flex xs10>
              <v-text-field v-model="password"
                            :label="$t('common.field__password-new-header')"
                            prepend-inner-icon="keyboard"
                            :append-icon="visible_new_password ? 'visibility_off' : 'visibility'"
                            @click:append="visible_new_password=!visible_new_password"
                            :type="visible_new_password ? 'text' : 'password'"
                            :rules="passwordRules"
                            clearable
              ></v-text-field>
            </v-flex>

            <v-flex xs10>
              <v-text-field v-model="password_confirm"
                            :label="$t('common.field__password-confirm-header')"
                            prepend-inner-icon="keyboard"
                            :append-icon="visible_new_password ? 'visibility_off' : 'visibility'"
                            @click:append="visible_new_password=!visible_new_password"
                            :type="visible_new_password ? 'text' : 'password'"
                            :rules="[() => !!password || $t('common.field__text-validation-error'),
                                     () => password.length>=6 || $t('common.field__password-validation-error'),
                                     (v) => v.indexOf(password)=== 0 || $t('common.field__password-invalid-error')]"
                            clearable
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updatePassword" :loading="processing"
                   :disabled="passwordMismatch"
                   color="blue" block dark text>
              {{ $t('common.button__send') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'PasswordChange',
  props: {
    load: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      userEmail: state => state.user.email,
    }),
    processing: {
      get() {
        return this.$store.state.processing;
      },
      set(value) {
        return this.$store.commit('SET_PROCESSING_STATUS', value);
      },
    },
    passwordMismatch() {
      return !(this.old_password && this.password && this.password_confirm) ||
          !(this.password === this.password_confirm) ||
          !(this.password.length >= 6);
    },
    passwordRules() {
      return [v => !!v || this.$t('common.field__text-validation-error'),
        v => (v && v.length >= 6) || this.$t('common.field__password-validation-error')]
    }
  },
  data: () => ({
    visible_old_password: false,
    visible_new_password: false,
    old_password: '',
    password: '',
    password_confirm: '',
    // Object representing an alert into the recovery form.
    alert: {
      display: false,
      type: 'error',
      text: '',
    },
  }),
  methods: {
    /**
     * Updates an user password.
     */
    updatePassword() {
      this.processing = true;
      // eslint-disable-next-line no-undef
      axios.post(`${axios.defaults.baseURL}/user/password/update`,
          JSON.stringify({
            email: this.userEmail,
            old_password: this.old_password,
            password: this.password,
            // eslint-disable-next-line no-undef
            url: axios.defaults.baseURL
          })).then(() => {
        this.processing = false;
        this.$refs.form.reset()
        this.alert.type = 'success';
        this.alert.text = this.$t('common.alert__password-change-success');
        this.alert.display = true;
      }).catch(error => {
        this.processing = false;
        // In case old password in wrong, we show a warning text.
        if (error.response.status === 401) {
          this.alert.type = 'warning';
          this.alert.text = this.$t('common.field__passwords-mismatch');
          this.alert.display = true;
        } else { // Any other error.
          this.$refs.form.reset()
          this.alert.type = 'error';
          this.alert.text = this.$t('common.dialog__error');
          this.alert.display = true;
        }
      });
    },
    closePasswordChangeForm() {
      this.alert.display = false;
      this.$refs.form.reset();
      this.$store.dispatch('setDialogStatus', {
        name: 'password_change',
        value: false
      })
    },
  }
};
</script>

<style scoped>

</style>
